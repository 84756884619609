<template>
	<Dialog :dialog="dialog" title="Invoice" current :dialogWidth="600" v-on:close="dialog = false">
		<template v-slot:title>Generate Invoice </template>
		<template v-slot:body>
			<table width="100%" class="detail-table scattered-table">
				<div class="">
					<v-form v-if="dialog" ref="invoiceForm" lazy-validation>
						<v-row>
							<v-col md="3">
								<label class="btx-label mt-4">Title</label>
							</v-col>
							<v-col md="9">
								<TextInput
									hide-details
									v-model="invoice.title"
									placeholder="Title"
									:disabled="pageLoading"
									:loading="pageLoading"
									:rules="[vrules.required(invoice.title, 'Title')]"
									:class="{
										required: !invoice.title,
									}"
								></TextInput>
							</v-col>
							<v-col md="3">
								<label class="btx-label mt-4">Amount</label>
							</v-col>
							<v-col md="3">
								<TextInput
									hide-details
									type="number"
									v-model="invoice.amount"
									placeholder="Amount"
									:disabled="pageLoading"
									:loading="pageLoading"
									:rules="[vrules.required(invoice.amount, 'amount')]"
									:class="{
										required: !invoice.amount,
									}"
								></TextInput>
							</v-col>
							<v-col md="3">
								<label class="btx-label mt-4 text-right">Invoice No</label>
							</v-col>
							<v-col md="3">
								<TextInput
									hide-details
									type="number"
									v-model="invoice.invoice_no"
									placeholder="Invoice No"
									:disabled="pageLoading"
									:loading="pageLoading"
									:rules="[vrules.required(invoice.invoice_no, 'Invoice No')]"
									:class="{
										required: !invoice.invoice_no,
									}"
								></TextInput>
							</v-col>
							<v-col md="3">
								<label class="btx-label mt-4">Attachment</label>
							</v-col>
							<v-col md="9">
								<FileUpload :allowAddMore="false" v-model="invoice.attachment"></FileUpload>
							</v-col>
							<v-col md="3">
								<label class="btx-label mt-4">Date</label>
							</v-col>

							<v-col md="9" class="py-0">
								<DatePicker
									hide-details
									:disabled="pageLoading"
									:loading="pageLoading"
									id="milestone_date"
									v-model="invoice.date"
								></DatePicker>
							</v-col>
						</v-row>
					</v-form>
				</div>
			</table>
		</template>
		<template v-slot:action>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close')"> Close </v-btn>
			<v-btn class="white--text" depressed color="blue darken-4" tile @click="updateOrCreate">
				Save
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
// import { CreateProforma, /* updateProforma */ } from "@/core/lib/project.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import DatePicker from "@/view/components/DatePicker";
import FileUpload from "@/view/components/FileUpload";
// import TextAreaInput from "@/view/components/TextAreaInput";
import { mapGetters } from "vuex";
import { createInvoice } from "@/core/lib/project.lib";
// import TextAreaInput from "@/view/components/TextAreaInput";
import ListingMixin from "@/core/mixins/listing.mixin";
export default {
	name: "create-invoice",
	title: "Create Invoice",
	mixins: [ListingMixin],
	components: {
		Dialog,
		TextInput,
		FileUpload,
		// TextAreaInput,
		DatePicker,
	},
	props: {
		dialog: {
			type: Boolean,
			default: false,
		},
		// showMoreFields: {
		// 	type: Boolean,
		// 	default: false,
		// },
		// currentProforma: {
		// 	type: Object,
		// 	default: () => {},
		// },
		project: {
			type: Object,
			default: () => {},
		},
		performId: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			invoice: {
				title: null,
				amount: null,
				invoice_no: null,
				attachment: null,
				date: null,
			},
		};
	},
	methods: {
		async updateOrCreate() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.invoiceForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.invoiceForm.validate()) {
				return false;
			}
			const formData = {
				project: _this.project.id,
				title: _this.invoice.title,
				amount: _this.invoice.amount,
				invoice_no: _this.invoice.invoice_no,
				date: _this.invoice.date,
				proforma: _this.performId,
				files: _this.invoice.attachment,
			};
			try {
				_this.pageLoading = true;
				await createInvoice(formData);
				_this.$emit("loadInvoice");
				_this.$emit("close");
				_this.$store.commit(SET_MESSAGE, [
					{ model: true, message: "Success ! Invoice has been created." },
				]);
				this.invoice = {
					title: null,
					amount: null,
					invoice_no: null,
					attachment: null,
					date: null,
				};
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
	},
	mounted() {
		this.pageLoading = false;
	},
	computed: {
		...mapGetters(["errors"]),
	},
};
</script>
