<template>
	<Dialog
		:dialog="addDialog"
		title="Proforma"
		current
		:dialogWidth="900"
		v-on:close="addDialog = false"
	>
		<template v-slot:title v-if="showMoreFields">Edit Proforma </template>
		<template v-slot:title v-else>Generate Proforma </template>
		<template v-slot:body>
			<table width="100%" class="detail-table scattered-table">
				<div>
					<v-form v-if="addDialog" ref="proformaForm">
						<v-row>
							<v-col md="3">
								<label for="team" class="btx-label mt-2">Name</label>
							</v-col>
							<v-col md="9">
								<TextInput
									hide-details
									placeholder="Title"
									v-model="proforma.title"
									:disabled="pageLoading"
									:loading="pageLoading"
								></TextInput>
							</v-col>
							<v-col md="3">
								<label for="team" class="btx-label mt-2">Amount</label>
							</v-col>
							<v-col md="9">
								<TextInput
									hide-details
									type="number"
									placeholder="Amount"
									v-model="proforma.amount"
									:disabled="pageLoading"
									:loading="pageLoading"
								></TextInput>
							</v-col>
							<v-col md="3">
								<label for="team" class="btx-label mt-2">Attachment</label>
							</v-col>
							<v-col md="9">
								<FileUpload :allowAddMore="false" v-model="proforma.attachment"></FileUpload>
							</v-col>
							<template v-if="showMoreFields || showLineItems">
								<v-col md="3">
									<label for="team" class="btx-label mt-2">Certificate</label>
								</v-col>
								<v-col md="9">
									<FileUpload :allowAddMore="false" v-model="proforma.certificate"></FileUpload>
								</v-col>
								<v-col md="3">
									<label for="team" class="btx-label mt-2">Invoice Amount</label>
								</v-col>
								<v-col md="9">
									<TextInput
										hide-details
										type="number"
										placeholder="Amount"
										v-model="proforma.invoice_amount"
										:disabled="pageLoading"
										:loading="pageLoading"
									>
									</TextInput>
								</v-col>
							</template>
							<template v-if="showLineItems">
								<div
									class="middle-header-background d-flex align-items-center justify-content-between my-2"
								>
									<p class="mb-2 px-4">
										Line items<br />
										<span>
											<v-icon small>mdi-progress-question</v-icon> Specify the Line Items for your
											Quotation</span
										>
									</p>
								</div>
								<div class="budget-listing">
									<v-simple-table class="bt-table listing-table table-head-sticky">
										<thead>
											<tr>
												<th class="simple-table-th">
													<!-- <v-checkbox dense @click="selectAllQuotations"></v-checkbox> -->
												</th>
												<th class="simple-table-th">#</th>
												<th width="40px">Quote #</th>
												<th class="simple-table-th">Name</th>
												<!-- <th class="simple-table-th">Product/Service</th> -->
												<th class="simple-table-th">Price</th>
												<!-- <th class="simple-table-th">QTY Ordered</th> -->
												<th class="simple-table-th">UOM</th>
												<th class="simple-table-th">QTY</th>
												<!-- <th max-width="150px" class="simple-table-th">Deliverable</th> -->
												<th class="simple-table-th">QTY to be delivered</th>
												<th class="simple-table-th">Gap</th>
											</tr>
										</thead>
										<tbody v-if="lineItemsList.length">
											<tr class="cursor-pointer" v-for="(row, index) in lineItemsList" :key="index">
												<td class="td_column cursor-default simple-table-td">
													<v-checkbox dense v-model="checkboxValue[index]"></v-checkbox>
												</td>
												<td class="td_column cursor-default simple-table-td">{{ index + 1 }}</td>
												<td class="td_column cursor-default simple-table-td">
													<Chip tooltip tooltip-text="Quotation" small text="QT0005" color="blue darken-4">
													</Chip>
												</td>
												<td class="td_column cursor-default simple-table-td">
													<ShowValue :object="row" object-key="name" label="Name"> </ShowValue>
												</td>
												<!-- <td class="td_column cursor-default simple-table-td">
                                                <ShowValue :object="row" object-key="product_name" label="product name">
                                                </ShowValue>
                                            </td> -->
												<td class="td_column cursor-default simple-table-td">
													<ShowPrice :object="row" object-key="price" label="Price"> </ShowPrice>
												</td>
												<!-- <td class="td_column cursor-default simple-table-td">
                                                <ShowValue :object="row" object-key="description" label="Description">
                                                </ShowValue>
                                            </td> -->
												<td class="td_column cursor-default simple-table-td">
													<ShowValue :object="row" object-key="uom" label="uom"> </ShowValue>
												</td>
												<td class="td_column cursor-default simple-table-td">
													<ShowValue :object="row" object-key="quantity" label="Quantity"> </ShowValue>
												</td>
												<!-- <td class="td_column cursor-default simple-table-td">
                                                <ShowValue :object="row" object-key="deliverable" label="Deliverable">
                                                </ShowValue>
                                            </td> -->
												<td class="td_column cursor-default simple-table-td">
													<!-- <ShowValue :object="row" object-key="qty_to_be_delivered"
                                                    label="qty to be delivered">
                                                </ShowValue> -->
													<TextInput
														hide-details
														id="address-label"
														placeholder="Quantity To Be Delivered"
														v-model.number="quantity_to_delivered"
													>
													</TextInput>
												</td>
												<td class="td_column cursor-default simple-table-td">
													<ShowValue :object="row" object-key="gap" label="gap"> </ShowValue>
												</td>
											</tr>
										</tbody>
										<tfoot v-else>
											<tr>
												<td colspan="11">
													<p class="m-0 row-not-found text-center py-3">
														<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
														Uhh... There are no task at the moment.
													</p>
												</td>
											</tr>
										</tfoot>
									</v-simple-table>
								</div>
							</template>
						</v-row>
					</v-form>
				</div>
			</table>
		</template>
		<template v-slot:action>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', false)"> Close </v-btn>
			<v-btn @click="createProforma()" class="white--text" depressed color="blue darken-4" tile>
				Save
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
// import { CreateProforma, /* updateProforma */ } from "@/core/lib/project.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import FileUpload from "@/view/components/FileUpload";
import {
	UPDATE_PROFORMA,
	GET_PROFORMA_BY_PROJECT,
	CREATE_PROFORMA,
} from "@/core/services/store/proforma.module.js";
// import TextAreaInput from "@/view/components/TextAreaInput";
import ShowValue from "@/view/components/ShowValue";
import ShowPrice from "@/view/components/ShowPrice";
import Chip from "@/view/components/Chip";
export default {
	name: "asset-internal-listing",
	title: "Listing Internal Asset",
	components: {
		Dialog,
		ShowValue,
		TextInput,
		FileUpload,
		ShowPrice,
		Chip,
	},
	props: {
		addDialog: {
			type: Boolean,
			default: false,
		},
		showMoreFields: {
			type: Boolean,
			default: false,
		},
		currentProforma: {
			type: Object,
			default: () => {},
		},
		project: {
			type: Object,
			default: () => {},
		},
		milestoneId: {
			type: Number,
		},
		showLineItems: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			pageLoading: false,
			checkboxValue: [],
			isDeliveryExistDialog: false,
			contact_person: [],
			quantity_to_delivered: null,
			lineItemsList: [
				{
					quote: "QT005",
					name: "line item 1",
					price: 4500,
					uom: 542,
					quantity: 14,
					deliverable: 14,
					qty_to_be_delivered: 10,
					gap: 4,
				},

				{
					quote: "QT001",
					name: "line item 2",
					price: 500,
					uom: 42,
					quantity: 10,
					deliverable: 7,
					qty_to_be_delivered: 5,
					gap: 2,
				},
			],
			proforma: {
				title: null,
				amount: null,
				attachment: [],
				certificate: null,
				invoice_amount: null,
			},
		};
	},
	methods: {
		async createProforma() {
			const _this = this;
			_this.pageLoading = true;
			const params = {
				project: _this.project.id,
				title: _this.proforma.title,
				customer: this.project.contact,
				project_milestone: this.milestoneId,
				amount: _this.proforma.amount,
				files: _this.proforma.attachment,
				certificate: _this.proforma.certificate,
				invoice_amount: this.proforma.invoice_amount,
			};
			try {
				if (this.showMoreFields) {
					await this.$store.dispatch(UPDATE_PROFORMA, {
						uuid: this.currentProforma.uuid,
						params: params,
					});
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Proforma has been updated." },
					]);
				} else {
					await this.$store.dispatch(CREATE_PROFORMA, params);
					_this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Proforma has been created." },
					]);
				}
				await this.loadProforma();
				(_this.proforma = {
					title: null,
					amount: null,
					attachment: [],
					certificate: [],
					invoice_amount: null,
				}),
					this.$emit("close", false);
				this.$emit("load");
			} catch (error) {
				_this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			} finally {
				_this.pageLoading = false;
			}
		},
		async loadProforma() {
			this.pageLoading = true;
			try {
				await this.$store.dispatch(GET_PROFORMA_BY_PROJECT, this.project.uuid);
				this.pageLoading = false;
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				this.pageLoading = false;
			}
		},
	},
	watch: {
		currentProforma(newProject) {
			if (newProject) {
				this.proforma = {
					title: newProject.title,
					amount: newProject.amount,
					invoice_amount: newProject.invoice_amount,
				};
			}
		},
	},
	mounted() {
		for (var i = 1; i <= 2; i++) {
			this.checkboxValue.push(false);
		}
	},
};
</script>
